import React from "react";
import Link from "next/link";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { styled } from "@eleo/components/styles";
import { SearchInput } from "@eleo/components/components/Input/SearchInput";
import Button from "@eleo/components/components/Button/Button";

const Container = styled("div", {
  alignItems: "center",
  columnGap: "$2",
  rowGap: "4px",
  display: "grid",
  gridTemplateColumns: "1fr",
  padding: "$1 $2",

  "@bp1a": {
    borderBottom: "1px solid $darkShade4",
    gridTemplateColumns: "120px 1fr 120px",
    padding: "$1 $2",
  },
});

const LogoContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  padding: "$2",
  width: "100%",

  "@bp1a": {
    alignItems: "unset",
    justifyContent: "unset",
    marginRight: "$4",
  },
});

const Logo = styled("img", {
  height: "auto",
  width: "80px",
});

const SearchForm = styled("form", {
  display: "flex",
  margin: "0 auto",
  maxWidth: "90vw",
  width: "480px",
});

const UpsellLink = styled("a", {
  display: "none",

  "@bp1a": {
    display: "block",
  },
});

const RightLinksContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  justifyContent: "flex-end",
});

const CustomerTopNav = () => {
  const router = useRouter();

  const searchTerm = router.query.query as string;

  const { handleSubmit, register } = useForm<{
    query: string;
  }>({
    defaultValues: {
      query: searchTerm,
    },
  });

  const onSubmit = ({ query }: { query: string }) => {
    router.push(`/search/${query}`);
  };

  return (
    <Container>
      <Link href={"https://eleo.store"}>
        <LogoContainer>
          <Logo src="/images/logo.svg" alt="Eleo" />
        </LogoContainer>
      </Link>

      <SearchForm onSubmit={handleSubmit(onSubmit)}>
        <SearchInput defaultValue={searchTerm} {...register("query")} />
      </SearchForm>

      <RightLinksContainer>
        <UpsellLink
          href={
            "https://eleo.app?utm_source=eleo&utm_medium=nav&utm_campaign=landing"
          }
        >
          <Button type={"button"} size="small" design="textOnly">
            Create Your Store
          </Button>
        </UpsellLink>
      </RightLinksContainer>
    </Container>
  );
};

export default CustomerTopNav;
