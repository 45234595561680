import React, { forwardRef, ReactNode } from "react";
import { VariantProps } from "@stitches/react";
import { styled } from "../../styles";

const gridColumnValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const gridColumnOptions: {
  [key: number | string]: any;
} = gridColumnValues.reduce((acc, value) => {
  return {
    ...acc,
    [value]: {
      gridColumn: `span ${value}`,
    },
  };
}, {});

const BaseGridItem = styled("div", {
  minWidth: 0,

  defaultVariants: {
    mobile: "12",
  },

  variants: {
    alignText: {
      left: {
        textAlign: "left",
      },
      right: {
        textAlign: "right",
      },
      center: {
        textAlign: "center",
      },
    },

    hideOnMobile: {
      true: {
        "@bp0": {
          display: "none",
        },
      },
    },

    hideOnPrint: {
      true: {
        "@print": {
          display: "none !important",
        },
      },
    },

    centered: {
      true: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },

    centerVertically: {
      true: {
        display: "flex",
        alignItems: "center",
      },
    },

    mobile: {
      ...gridColumnOptions,
    },

    tablet: {
      ...gridColumnOptions,
    },

    desktop: {
      ...gridColumnOptions,
    },

    xxl: {
      ...gridColumnOptions,
    },

    xxxl: {
      ...gridColumnOptions,
    },
  },
});

export type GridColumnOptions = keyof typeof gridColumnOptions;

export type GridItemProps = VariantProps<typeof BaseGridItem> & {
  children: ReactNode;
  xxl?: GridColumnOptions;
  xxxl?: GridColumnOptions;
  desktop?: GridColumnOptions;
  tablet?: GridColumnOptions;
  css?: any;
  as?: string;
  id?: string;
  onClick?: (event: MouseEvent) => void;
  hideOnMobile?: boolean;
  title?: string;
  alignText?: "left" | "right" | "center";
};

export const GridItem = forwardRef<HTMLDivElement, GridItemProps>(
  (props, ref) => {
    const {
      children,
      desktop,
      mobile,
      tablet,
      centered,
      xxl,
      xxxl,
      onClick,
      hideOnMobile,
      alignText,
      ...rest
    } = props;

    return (
      <BaseGridItem
        ref={ref}
        xxxl={{
          "@bp5": xxxl,
        }}
        xxl={{
          "@bp4a": xxl,
        }}
        desktop={{
          "@bp3a": desktop,
        }}
        tablet={{
          "@bp2": tablet,
        }}
        mobile={mobile}
        centered={centered}
        onClick={onClick as any}
        hideOnMobile={hideOnMobile}
        alignText={alignText}
        {...rest}
      >
        {children}
      </BaseGridItem>
    );
  },
);

GridItem.displayName = "GridItem";
