import { globalCss } from "./stitches.config";
import reset from "./reset";

export const globalStyles = globalCss({
  ...reset,
  "@import":
    'url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@600&display=swap")',

  "*": {
    boxSizing: "border-box",
  },

  body: {
    backgroundColor: "$white",
    color: "$textActive",
    fontFamily: "$body",
    fontSize: "14px",
    scrollBehavior: "smooth",
    "webkit-font-smoothing": "antialiased",
    textRendering: "optimizeLegibility",
    touchAction: "manipulation",
    padding: 0,
    margin: 0,
  },

  html: {
    fontSize: "62.5%",
    scrollBehavior: "smooth",
    "webkit-font-smoothing": "antialiased",
    textRendering: "optimizeLegibility",
    touchAction: "manipulation",
    padding: 0,
    margin: 0,
  },

  a: {
    color: "inherit",
    textDecoration: "none",
  },

  img: {
    maxWidth: "100%",
  },

  picture: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },

  ".ql-toolbar.ql-snow": {
    fontFamily: "$body",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },

  ".ql-toolbar.ql-snow+.ql-container.ql-snow": {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },

  ".react-datepicker__input-container": {
    input: {
      backgroundColor: "$darkSlateGrayOpacity30",
      border: "none",
      borderRadius: "8px",
      color: "$textActive",
      fontFamily: "$body",
      fontSize: "16px",
      fontWeight: "600",
      height: "56px",
      outline: "2px solid transparent",
      padding: "$2",
      transition: "all .2s ease-in-out",
      width: "100%",

      "&:focus": {
        outlineColor: "$primary",
      },

      "@bp1a": {
        fontSize: "14px",
      },
    },
  },

  ".eleoModalOverlay": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: "$eAbsolute",
  },

  ".eleoModal": {
    backgroundColor: "$white",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    margin: "auto",
    maxHeight: "90vh",
    maxWidth: "90vw",
    overflow: "auto",
    padding: "$3",
    position: "relative",
    width: "fit-content",
    zIndex: "$eAbsolute",
  },

  ".easy-edit-button-wrapper": {
    display: "inline-flex",
    columnGap: "8px",
    padding: "4px",
  },

  ".easy-edit-button": {
    fontSize: "12px !important",
  },

  "@print": {
    ".ReactQueryDevtools": {
      display: "none",
    },
  },
});
