import Input, { InputProps } from "./Input";
import Button from "../Button/Button";
import { styled } from "../../styles";
import React, { forwardRef, useState } from "react";

export type SearchInputProps = InputProps & {
  defaultValue?: string;
};

const Container = styled("div", {
  alignItems: "center",
  // backgroundColor: "$darkSlateGrayOpacity30",/**/
  // backgroundColor: "red",
  border: "1px solid $darkSlateGrayOpacity30",
  borderRadius: "16px",
  display: "flex",
  transition: "all 0.3s ease-in-out",
  width: "100%",

  "@bp1a": {
    maxWidth: "700px",
    padding: "0 0 0 $2",
    width: "100%",
  },

  input: {
    backgroundColor: "transparent",
    fontSize: "12px",

    "&:focus": {
      outline: "none",
    },
  },

  button: {
    borderBottomRightRadius: "16px",
    borderTopRightRadius: "16px",
    // width: "148px",
  },

  variants: {
    isFocused: {
      true: {
        backgroundColor: "$white",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
  },
});

const SearchIcon = styled("img", {
  display: "none",
  height: "16px",
  width: "16px",

  "@bp1a": {
    display: "block",
  },
});

const SearchInputButton = styled(Button, {
  padding: "$2 0",

  [`& ${SearchIcon}`]: {
    display: "block",
    filter: "invert(1)",

    "@bp1a": {
      display: "none",
    },
  },

  span: {
    display: "none",

    "@bp1a": {
      display: "inline-block",
    },
  },
});

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <Container isFocused={isFocused} onBlur={() => setIsFocused(false)}>
        <SearchIcon src="/images/icons/search.svg" alt="search" />

        <Input
          type="search"
          placeholder="Search product, stores, menus etc."
          ref={ref}
          required
          css={{
            height: "36px",
            padding: "4px $1",
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...props}
        />

        <SearchInputButton type={"submit"} mode="primary" size="small">
          <SearchIcon src="/images/icons/search.svg" alt="search" />

          <span>Search</span>
        </SearchInputButton>
      </Container>
    );
  },
);

SearchInput.displayName = "SearchInput";
